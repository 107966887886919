
.App {
  text-align: center;
  background-color: black;
  overflow-x: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.image-container {
  position: relative;
}

.top-image, .bottom-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ajustez à la taille désirée */
  height: auto; /* Ou fixez une hauteur spécifique si nécessaire */
}

.bottom-image {
  z-index: 3; /* S'assure que l'image du dessous reste en dessous */
}

.top-image {
  z-index: 2; /* Image du dessus par-dessus l'image du dessous */
}

.App .yarl__icon{
  height: 50px;
  width: 50px;
  background-color: black;
  border-radius: 100%;
}

.App .yarl__button{
  filter: none;
}



/* Cible toutes les barres de défilement */
::-webkit-scrollbar {
  width: 10px; /* Largeur de la barre de défilement */
}

/* Track/Axe de la barre de défilement */
::-webkit-scrollbar-track {
  background: #000000; /* Couleur de fond de l'axe */
}

/* Handle/Curseur de la barre de défilement */
::-webkit-scrollbar-thumb {
  background: #caa727; /* Couleur du curseur */
  border-radius: 5px; /* Arrondit les coins du curseur */
}

/* En survolant le curseur */
::-webkit-scrollbar-thumb:hover {
  background: #8d751c; /* Couleur du curseur au survol */
}

